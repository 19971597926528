.dragging{
    cursor:grabbing;
    stroke: #000; 
    stroke-width: 1px;
}
.node{
    cursor:pointer
}

#cy {
    width: 100%;
    height: 600px;
    position: relative;
    margin: auto;
    /* background: #fafafa; */
    /* background-image: radial-gradient(#bebebe 1px, transparent 0); */
    /* background-size: 18px 18px; */
  }
  
  .cyNode {
    width: 100px;
    min-height: 40px;
    /* min-height: 60px; */
    padding: 9px;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    color: white;
    /* background-color: #CECECE; */
    /* border-radius: 5px; */
    /* -moz-box-shadow: 3px 3px 3px rgba(68, 68, 68, 0.6);
    -webkit-box-shadow: 3px 3px 3px rgba(68, 68, 68, 0.6);
    box-shadow: 3px 3px 3px rgba(68, 68, 68, 0.6); */
    color: #333637;
    font-size: 9px;
    font-weight: 700;
    /* border-style: solid; */
    /* border-color: #333637; */
    cursor: pointer;
  }
  
  .nodeTitle {
    font-size: 12px;
    padding: 5px 0 5px 0;
    margin-bottom: 5px;
    /* text-shadow: 0.2px 0.2px 0 white; */
  }
  
  .nodeValue {
    width: 100px;
    font-size: 14px;
    padding: 5px;
    border-radius: 20px;
    /* background-color: rgba(255, 255, 255, 0.5);
    webkit-box-shadow: 0 5px 4px -4px #777;
    -moz-box-shadow: 0 5px 4px -4px #777;
    box-shadow: 0 5px 4px -4px #777; */
  }
  /* .finished{
    background-color: black;
    position: absolute;
    width: 15px;
    height: 15px;
    top:0;
    left:0
  } */
  .hide{
    opacity: .3;
  }
  .finished {
    position: absolute;
    width: 0; 
    height: 0;
    border-right: 0px solid green;
    border-left: 10px solid #000;
    border-top: 0px solid transparent;
    border-bottom: 10px solid transparent;
    top:0;
    left:0

}
  .nodeValue[value="undefined"] {
    display: none;
  }
  
  .goggleBtn[collapse="1"] {
    font-weight: 700;
    position: absolute;
    right: -5px;
    bottom: -5px;
    padding: 3px;
    width: 16px;
    height: 16px;
    color: #333637;
    border-style: solid;
    border-color: #333637;
    border-radius: 16px;
    background-color: white;
    webkit-box-shadow: 0 5px 4px -4px #777;
    -moz-box-shadow: 0 5px 4px -4px #777;
    box-shadow: 0 5px 4px -4px #777;
  }
  
  .goggleBtn[collapse="0"] {
    display: none;
  }
  
  .desc {
    
    box-sizing: border-box;
    background-color: white;
    display: none;
    font-size: .7em;
    font-weight: 300;
    border:1px solid
  }
  .popin{
    padding: 0px 5px 15px 5px;
    min-height: 70px;
    min-width: 120px;
  }
  .showPopin{
    display: block;
  }
  .detailTitle{
    font-weight: 600;
    color: #1da1d9;
  }
  .popinTitle{
    font-weight: 500;
    color: #10171a;
    text-transform: capitalize;
  }
  .popinClose{
    position: relative;
    top: 1px;
    left: 50%;
    padding: 3px 5px;
    font-weight: 500;
    cursor: pointer;
  }
  .filtred{
    border: 3px solid;
    box-shadow: 0 10px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19) !important;
  }
  .notFiltred{
    border: 0;
  }
  .warning{
    /* background-color: red; */
    /* border-radius: 3px 3px 0px 0px; */
    color:red;
    /* padding: 2px 5px; */
    position: absolute;
    right: 0px;
    top:-15px
  }
  .critical{
    border: 3px solid red !important
  }
  /* .wfToolbar{
    position: fixed;
    left:0;
    top:40%;
    width: 50px;
    height: 100%;
    z-index: 999999;
  } */
